<template>
  <div>
    <van-nav-bar title="级别佣金与团队说明" left-arrow @click-left="$router.back()"/>
    <div class="rich-text"><p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                                              style="font-family:SourceHanSansSC; font-weight:700; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;">一、返佣级别说明</span>
    </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;">1.1  返佣级别最多为三级，最少为二级，根据自购或者买货用户的身份来分辨。</span>
      </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;">1.2  小达人自购或买货返佣</span><span
          class="--mb--rich-text" data-boldtype="0"
          style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;">为三级，</span><span
          class="--mb--rich-text" data-boldtype="0"
          style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;">即A为小达人，A买货或自购之后，A本人、上级、上上级都可以获得返佣。</span>
      </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;">1.3  中达人自购或者买货返佣为二级，即B为中达人，B买货或自购之后，B本人、上级都可以获得返佣。</span>
      </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;">1.4  返佣金额根据商品不同返佣金额也不同，具体返佣金额已到账为准。</span>
      </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:700; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;">二、成为达人</span>
      </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;">2.1  在个人中心点击成为达人按钮，填写信息后，就有了自己的分享拉新码，拉到一个下级后即可以成为小达人。</span>
      </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;">2.2  当自己的下级有20个人后就可以自动升级成为中达人。</span>
      </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:700; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;">三、组建团队</span>
      </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;">3.1  注册申请成为达人，并拉取下级，这些下级就是您的团队成员，</span>
      </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;">团队成员自购或者推荐用户买货，您都有可能获得返佣，团队成员越多，获取佣金的机会就越多。</span>
      </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;">3.2  团队成员如果进行了商品退货，您之前获取的本订单的商品佣金也会随时减去。</span>
      </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;">3.3   团队列表中只展示您的下级，下下级此处不为您展示。</span>
      </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;">上述说明，本平台有最终解释权。</span>
      </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;">感谢您的耐心阅读！</span>
      </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;"><br></span>
      </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;"><br></span>
      </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;"><br></span>
      </p>
      <p style="line-height:0; margin-bottom:5px;"><span class="--mb--rich-text" data-boldtype="0"
                                                         style="font-family:SourceHanSansSC; font-weight:400; font-size:12px; color:rgb(16, 16, 16); font-style:normal; letter-spacing:0px; line-height:18px; text-decoration:none;"><br></span>
      </p></div>
  </div>
</template>
<script>
export default {
  name: "payProtocol"
}
</script>

<style scoped>

</style>
